const intro = {
    social: [
        {
            name: "Resume",
            url: "//drive.google.com/file/d/1aO2nE1a1WNWgoKpPpA7eDdo6nzd1dDn_/view?usp=sharing"
        },
        {
            name: "LinkedIn",
            url: "//www.linkedin.com/in/mcamilledavid"
        },
        {
            name: "Github",
            url: "//www.github.com/mcamilledavid"
        },
        {
            name: "Instagram",
            url: "//www.instagram.com/mcamilledavid"
        },
    ]
};

export default intro;
